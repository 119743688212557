var BBraunSpaceTwoPump = {
  attributeLimits: {
    'SPACE_Dle_Drug_Patient_Profile_Name' : { display: 'Clinical Modifier Name', maxBytes: 75, s: 130, f18b: 292, f20r: 299, f25r: 219 },
    'SPACE_Dle_Drug_Long_Name'            : { display: 'Master Drug', maxBytes: 75, s: 148, f18b: 257, f25b: 429, f25r: 491 },
    'SPACE_Dle_Drug_Other_Names'          : { display: 'Drug Brand Name', maxBytes: 75, s: 148, f18b: 257, f25b: 429, f25r: 491 },
    'SPACE_Dle_Drug_Alternate_Name'       : { display: 'Alternate Master Drug Name', maxBytes: 75, s: 148, f18b: 257, f25b: 429, f25r: 491 },
    'SPACE_Dle_Drug_Lookup'               : { display: 'Drug Lookup', maxBytes: 60 },
    'SPACE_Dle_Drug_Info_1'               : { display: 'Clinical Advisory Line 1',  maxBytes: 75, s: 145, f20r: 299 },
    'SPACE_Dle_Drug_Info_2'               : { display: 'Clinical Advisory Line 2', maxBytes: 75, s: 145, f20r: 299 },
    'SPACE_Dle_Drug_Info_3'               : { display: 'Clinical Advisory Line 3', maxBytes: 75, s: 145, f20r: 299 },
    'SPACE_Dle_CA_Drug_Info_1'            : { display: 'Clinical Advisory Line 1',  maxBytes: 75, s: 145, f20r: 598 },
    'SPACE_Dle_CA_Drug_Info_2'            : { display: 'Clinical Advisory Line 2', maxBytes: 75, s: 145, f20r: 598 },
    'SPACE_Dle_CA_Drug_Info_3'            : { display: 'Clinical Advisory Line 3', maxBytes: 75, s: 145, f20r: 598 },
    'SPACE_Dle_Drug_Category'             : { display: 'Category Name', maxBytes: 90, s: 130, f18b: 292 },
    'SPACE_Dle_Drug_Conc_Shortname'       : { display: 'Concentration Short Name', maxBytes: 24, s: 53, b:  84 },
    'SPACE_Dle_Drug_Conc_Lookup_Reference': { display: 'Concentration Lookup Reference', maxBytes: 60 },
    'SPACE_Dle_Drug_DLL3_Text_1'          : { display: 'Lock Level 3 Text 1', maxBytes: 60, s: 120 },
    'SPACE_Dle_Drug_DLL3_Text_2'          : { display: 'Lock Level 3 Text 2', maxBytes: 60, s: 120 }, 
    'SPACE_Dle_Physical_Location'         : { display: 'Location Name', maxBytes: 90, s: 130,f18b: 292 },
    'SPACE_Dle_Ward_Name'                 : { display: 'Care Area Name', maxBytes: 60, s: 112, f18b: 292, f18r: 178, f20r: 240, f25r: 219 }
  },

  characterSizes: {
    '0x0020': { c: ' ', s: 2, b: 3, f18b: 4, f18r: 4, f20r: 5, f25b: 7, f25r: 7, f35b: 9 },
    '0x0021': { c: '!', s: 2, b: 4, f18b: 5, f18r: 4, f20r: 6, f25b: 9, f25r: 7, f35b: 13 },
    '0x0022': { c: '"', s: 4, b: 8, f18b: 7, f18r: 6, f20r: 7, f25b: 9, f25r: 8, f35b: 12 },
    '0x0023': { c: '#', s: 6, b: 14, f18b: 12, f18r: 13, f20r: 13, f25b: 14, f25r: 17, f35b: 20 },
    '0x0024': { c: '$', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 15, f25r: 15, f35b: 21},
    '0x0025': { c: '%', s: 10, b: 19, f18b: 16, f18r: 14, f20r: 14, f25b: 19, f25r: 21, f35b: 26},
    '0x0026': { c: '&', s: 7, b: 14, f18b: 12, f18r: 11, f20r: 13, f25b: 18, f25r: 18, f35b: 25 },
    '0x0027': { c: "'", s: 2, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 5, f25r: 4, f35b: 6 },
    '0x0028': { c: '(', s: 4, b: 7, f18b: 6, f18r: 6, f20r: 8, f25b: 9, f25r: 9, f35b: 13 },
    '0x0029': { c: ')', s: 4, b: 7, f18b: 6, f18r: 6, f20r: 8, f25b: 9, f25r: 9, f35b: 13 },
    '0x002A': { c: '*', s: 4, b: 12, f18b: 12, f18r: 9, f20r: 12, f25b: 15, f25r: 14, f35b: 20 },
    '0x002B': { c: '+', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 16, f35b: 22 },
    '0x002C': { c: ',', s: 2, b: 4, f18b: 5, f18r: 4, f20r: 6, f25b: 8, f25r: 9, f35b: 12 },
    '0x002D': { c: '-', s: 4, b: 8, f18b: 7, f18r: 5, f20r: 6, f25b: 8, f25r: 8, f35b: 11 },
    '0x002E': { c: '.', s: 2, b: 5, f18b: 4, f18r: 3, f20r: 6, f25b: 8, f25r: 7, f35b: 12 },
    '0x002F': { c: '/', s: 4, b: 8, f18b: 5, f18r: 5, f20r: 5, f25b: 7, f25r: 7, f35b: 10 },
    '0x0030': { c: '0', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 22 },
    '0x0031': { c: '1', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x0032': { c: '2', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x0033': { c: '3', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x0034': { c: '4', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 20 },
    '0x0035': { c: '5', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 20 },
    '0x0036': { c: '6', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x0037': { c: '7', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 20 },
    '0x0038': { c: '8', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x0039': { c: '9', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 15, f35b: 21 },
    '0x003A': { c: ':', s: 2, b: 5, f18b: 4, f18r: 4, f20r: 5, f25b: 7, f25r: 8, f35b: 11 },
    '0x003B': { c: ';', s: 2, b: 4, f18b: 5, f18r: 5, f20r: 6, f25b: 7, f25r: 7, f35b: 11 },
    '0x003C': { c: '<', s: 6, b: 12, f18b: 10, f18r: 11, f20r: 12, f25b: 15, f25r: 15, f35b: 21 },
    '0x003D': { c: '=', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 15, f25r: 15, f35b: 21 },
    '0x003E': { c: '>', s: 6, b: 12, f18b: 10, f18r: 11, f20r: 12, f25b: 15, f25r: 15, f35b: 21 },
    '0x003F': { c: '?', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 9, f25b: 11, f25r: 11, f35b: 17 },
    '0x0040': { c: '@', s: 9, b: 19, f18b: 15, f18r: 14, f20r: 16, f25b: 21, f25r: 22, f35b: 31 },
    '0x0041': { c: 'A', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x0042': { c: 'B', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 15, f25r: 14, f35b: 21 },
    '0x0043': { c: 'C', s: 7, b: 13, f18b: 10, f18r: 10, f20r: 11, f25b: 16, f25r: 15, f35b: 22 },
    '0x0044': { c: 'D', s: 7, b: 13, f18b: 11, f18r: 11, f20r: 12, f25b: 17, f25r: 16, f35b: 25 },
    '0x0045': { c: 'E', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x0046': { c: 'F', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 9, f25b: 14, f25r: 13, f35b: 19 },
    '0x0047': { c: 'G', s: 7, b: 13, f18b: 11, f18r: 11, f20r: 13, f25b: 18, f25r: 17, f35b: 25 },
    '0x0048': { c: 'H', s: 7, b: 12, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b:  25},
    '0x0049': { c: 'I', s: 2, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10},
    '0x004A': { c: 'J', s: 5, b: 10, f18b: 6, f18r: 6, f20r: 7, f25b: 10, f25r: 9, f35b: 14 },
    '0x004B': { c: 'K', s: 7, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 16, f25r: 15, f35b: 23},
    '0x004C': { c: 'L', s: 6, b: 11, f18b: 8, f18r: 7, f20r: 9, f25b: 13, f25r: 12, f35b: 18 },
    '0x004D': { c: 'M', s: 8, b: 15, f18b: 14, f18r: 13, f20r: 15, f25b: 22, f25r: 21, f35b: 32 },
    '0x004E': { c: 'N', s: 7, b: 12, f18b: 11, f18r: 11, f20r: 12, f25b: 18, f25r: 17, f35b: 26 },
    '0x004F': { c: 'O', s: 8, b: 14, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27 },
    '0x0050': { c: 'P', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 9, f25b: 15, f25r: 13, f35b: 20 },
    '0x0051': { c: 'Q', s: 8, b: 14, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27 },
    '0x0052': { c: 'R', s: 7, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 15, f25r: 13, f35b: 21 },
    '0x0053': { c: 'S', s: 7, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x0054': { c: 'T', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 19 },
    '0x0055': { c: 'U', s: 7, b: 12, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x0056': { c: 'V', s: 6, b: 13, f18b: 11, f18r: 11, f20r: 12, f25b: 16, f25r: 15, f35b: 23 },
    '0x0057': { c: 'W', s: 8, b: 17, f18b: 16, f18r: 14, f20r: 18, f25b: 23, f25r: 23, f35b: 33 },
    '0x0058': { c: 'X', s: 7, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 17, f25r: 15, f35b: 25 },
    '0x0059': { c: 'Y', s: 6, b: 12, f18b: 9, f18r: 10, f20r: 10, f25b: 16, f25r: 13, f35b: 22 },
    '0x005A': { c: 'Z', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 20 },
    '0x005B': { c: '[', s: 3, b: 6, f18b: 7, f18r: 6, f20r: 7, f25b: 9, f25r: 9, f35b: 13 },
    '0x005C': { c: '\\', s: 4, b: 6, f18b: 5, f18r: 5, f20r: 5, f25b: 8, f25r: 7, f35b: 10 },
    '0x005D': { c: ']', s: 3, b: 6, f18b: 7, f18r: 6, f20r: 7, f25b: 9, f25r: 9, f35b: 13 },
    '0x005E': { c: '^', s: 6, b: 10, f18b: 9, f18r: 11, f20r: 12, f25b: 16, f25r: 15, f35b: 21 },
    '0x005F': { c: '_', s: 6, b: 12, f18b: 8, f18r: 9, f20r: 11, f25b: 13, f25r: 14, f35b: 18 },
    '0x0060': { c: '`', s: 3, b: 5, f18b: 9, f18r: 9, f20r: 10, f25b: 13, f25r: 13, f35b: 17 },
    '0x0061': { c: 'a', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20},
    '0x0062': { c: 'b', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0063': { c: 'c', s: 6, b: 11, f18b: 8, f18r: 8, f20r: 9, f25b: 13, f25r: 12, f35b: 18 },
    '0x0064': { c: 'd', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 21 },
    '0x0065': { c: 'e', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x0066': { c: 'f', s: 4, b: 9, f18b: 6, f18r: 6, f20r: 7, f25b: 10, f25r: 9, f35b: 13 },
    '0x0067': { c: 'g', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0068': { c: 'h', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0069': { c: 'i', s: 3, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x006A': { c: 'j', s: 4, b: 5, f18b: 5, f18r: 3, f20r: 5, f25b: 7, f25r: 6, f35b: 10 },
    '0x006B': { c: 'k', s: 5, b: 11, f18b: 9, f18r: 7, f20r: 9, f25b: 14, f25r: 12, f35b: 19 },
    '0x006C': { c: 'l', s: 2, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x006D': { c: 'm', s: 8, b: 16, f18b: 14, f18r: 13, f20r: 16, f25b: 23, f25r: 22, f35b: 32 },
    '0x006E': { c: 'n', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x006F': { c: 'o', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0070': { c: 'p', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0071': { c: 'q', s: 6, b: 11, f18b:10 , f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22},
    '0x0072': { c: 'r', s: 4, b: 8, f18b: 6, f18r: 5, f20r: 6, f25b: 10, f25r: 8, f35b: 14 },
    '0x0073': { c: 's', s: 6, b: 11, f18b: 8, f18r: 7, f20r: 8, f25b: 12, f25r: 10, f35b: 17 },
    '0x0074': { c: 't', s: 4, b: 7, f18b: 6, f18r: 6, f20r: 6, f25b: 10, f25r: 10, f35b: 13 },
    '0x0075': { c: 'u', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0076': { c: 'v', s: 6, b: 11, f18b: 9, f18r: 9, f20r: 9, f25b: 13, f25r: 12, f35b: 19 },
    '0x0077': { c: 'w', s: 8, b: 15, f18b: 13, f18r: 13, f20r: 17, f25b: 21, f25r: 21, f35b: 30 },
    '0x0078': { c: 'x', s: 6, b: 10, f18b: 8, f18r: 7, f20r: 9, f25b: 14, f25r: 12, f35b: 19 },
    '0x0079': { c: 'y', s: 6, b: 10, f18b: 9, f18r: 8, f20r: 9, f25b: 14, f25r: 12, f35b: 19 },
    '0x007A': { c: 'z', s: 6, b: 10, f18b: 9, f18r: 8, f20r: 9, f25b: 12, f25r: 12, f35b: 16 },
    '0x007B': { c: '{', s: 4, b: 6, f18b: 6, f18r: 5, f20r: 8, f25b: 10, f25r: 10, f35b: 13 },
    '0x007C': { c: '|', s: 2, b: 4, f18b: 4, f18r: 5, f20r: 6, f25b: 7, f25r: 8, f35b: 10 },
    '0x007D': { c: '}', s: 4, b: 6, f18b: 6, f18r: 5, f20r: 8, f25b: 10, f25r: 10, f35b: 13 },
    '0x007E': { c: '~', s: 9, b: 12, f18b: 9, f18r: 11, f20r: 12, f25b: 15, f25r: 15, f35b: 20 },
    '0x00A1': { c: '¡', s: 2, b: 4, f18b: 5, f18r: 4, f20r: 6, f25b: 9, f25r: 7, f35b: 13 },
    '0x00AB': { c: '«', s: 6, b: 13, f18b: 10, f18r: 9, f20r: 10, f25b: 15, f25r: 13, f35b: 20 },
    '0x00B0': { c: '°', s: 4, b: 8, f18b: 5, f18r: 5, f20r: 6, f25b: 8, f25r: 9, f35b: 11 },
    '0x00B2': { c: '²', s: 4, b: 6, f18b: 6, f18r: 6, f20r: 7, f25b: 10, f25r: 9, f35b: 13 },
    '0x00B5': { c: 'µ', s: 6, b: 11, f18b: 9, f18r: 9, f20r: 10, f25b: 15, f25r: 15, f35b: 22 },
    '0x00B7': { c: '·', s: 3, b: 6, f18b: 4, f18r: 5, f20r: 6, f25b: 7, f25r: 7, f35b: 10 },
    '0x00BB': { c: '»', s: 6, b: 13, f18b: 10, f18r: 9, f20r: 10, f25b: 15, f25r: 13, f35b: 20 },
    '0x00BF': { c: '¿', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 9, f25b: 12, f25r: 11, f35b: 17 },
    '0x00C0': { c: 'À', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C1': { c: 'Á', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C2': { c: 'Â', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C3': { c: 'Ã', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C4': { c: 'Ä', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C5': { c: 'Å', s: 8, b: 13, f18b: 11, f18r: 10, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x00C6': { c: 'Æ', s: 10, b: 19, f18b: 16, f18r: 15, f20r: 18, f25b: 24, f25r: 24, f35b: 34 },
    '0x00C7': { c: 'Ç', s: 6, b: 13, f18b: 10, f18r: 10, f20r: 11, f25b: 16, f25r: 15, f35b: 22 },
    '0x00C8': { c: 'È', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x00C9': { c: 'É', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x00CA': { c: 'Ê', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x00CB': { c: 'Ë', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x00CC': { c: 'Ì', s: 3, b: 6, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 11 },
    '0x00CD': { c: 'Í', s: 4, b: 5, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 11 },
    '0x00CE': { c: 'Î', s: 4, b: 8, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 11 },
    '0x00CF': { c: 'Ï', s: 4, b: 8, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 11 },
    '0x00D0': { c: 'Ð', s: 7, b: 13, f18b:11, f18r: 11, f20r: 13, f25b: 17, f25r: 16, f35b: 25 },
    '0x00D1': { c: 'Ñ', s: 6, b: 12, f18b: 11, f18r: 11, f20r: 12, f25b: 18, f25r: 17, f35b: 26 },
    '0x00D2': { c: 'Ò', s: 8, b: 12, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27},
    '0x00D3': { c: 'Ó', s: 8, b: 12, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27},
    '0x00D4': { c: 'Ô', s: 8, b: 12, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27},
    '0x00D5': { c: 'Õ', s: 8, b: 14, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27},
    '0x00D6': { c: 'Ö', s: 8, b: 14, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27},
    '0x00D7': { c: '×', s: 4, b: 8, f18b: 10, f18r: 9, f20r: 11, f25b: 16, f25r: 15, f35b: 20 },
    '0x00D8': { c: 'Ø', s: 8, b: 14, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27 },
    '0x00D9': { c: 'Ù', s: 7, b: 11, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x00DA': { c: 'Ú', s: 7, b: 11, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x00DB': { c: 'Û', s: 7, b: 12, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x00DC': { c: 'Ü', s: 7, b: 12, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x00DD': { c: 'Ý', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 26, f25r: 15, f35b: 22},
    '0x00DE': { c: 'Þ', s: 6, b: 12, f18b: 9, f18r: 7, f20r: 9, f25b: 15, f25r: 13, f35b: 20 },
    '0x00DF': { c: 'ß', s: 7, b: 13, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00E0': { c: 'à', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E1': { c: 'á', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E2': { c: 'â', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E3': { c: 'ã', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E4': { c: 'ä', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E5': { c: 'å', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x00E6': { c: 'æ', s: 10, b: 19, f18b: 15, f18r: 14, f20r: 16, f25b: 22, f25r: 21, f35b: 31 },
    '0x00E7': { c: 'ç', s: 6, b: 11, f18b: 8, f18r: 8, f20r: 9, f25b: 13, f25r: 12, f35b: 17 },
    '0x00E8': { c: 'è', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x00E9': { c: 'é', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x00EA': { c: 'ê', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x00EB': { c: 'ë', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x00EC': { c: 'ì', s: 3, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x00ED': { c: 'í', s: 3, b: 5, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x00EE': { c: 'î', s: 4, b: 6, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x00EF': { c: 'ï', s: 4, b: 6, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x00F0': { c: 'ð', s: 5, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x00F1': { c: 'ñ', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x00F2': { c: 'ò', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F3': { c: 'ó', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F4': { c: 'ô', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F5': { c: 'õ', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F6': { c: 'ö', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F8': { c: 'ø', s: 6, b: 12, f18b: 11, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00F9': { c: 'ù', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x00FA': { c: 'ú', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x00FB': { c: 'û', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x00FC': { c: 'ü', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x00FD': { c: 'ý', s: 6, b: 10, f18b: 9, f18r: 8, f20r: 9, f25b: 14, f25r: 12, f35b: 19 },
    '0x00FE': { c: 'þ', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x00FF': { c: 'ÿ', s: 6, b: 10, f18b: 9, f18r: 8, f20r: 9, f25b: 14, f25r: 12, f35b: 19 },
    '0x0104': { c: 'Ą', s: 6, b: 13, f18b: 11, f18r: 11, f20r: 12, f25b: 17, f25r: 15, f35b: 24 },
    '0x0105': { c: 'ą', s: 6, b: 11, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x0106': { c: 'Ć', s: 7, b: 13, f18b: 10, f18r: 10, f20r: 11, f25b: 16, f25r: 15, f35b: 22 },
    '0x0107': { c: 'ć', s: 6, b: 11, f18b: 8, f18r: 8, f20r: 9, f25b: 13, f25r: 12, f35b: 17 },
    '0x010C': { c: 'Č', s: 7, b: 13, f18b: 10, f18r: 10, f20r: 11, f25b: 16, f25r: 15, f35b: 22 },
    '0x010D': { c: 'č', s: 6, b: 11, f18b: 8, f18r: 8, f20r: 9, f25b: 13, f25r: 12, f35b: 17 },
    '0x010E': { c: 'Ď', s: 7, b: 13, f18b: 11, f18r: 11, f20r: 12, f25b: 17, f25r: 16, f35b: 25 },
    '0x010F': { c: 'ď', s: 8, b: 14, f18b: 12, f18r: 11, f20r: 13, f25b: 17, f25r: 16, f35b: 26 },
    '0x0110': { c: 'Đ', s: 8, b: 15, f18b: 12, f18r: 11, f20r: 13, f25b: 17, f25r: 16, f35b: 25 },
    '0x0111': { c: 'đ', s: 7, b: 12, f18b: 10, f18r: 9, f20r: 12, f25b: 15, f25r: 14, f35b: 22 },
    '0x0118': { c: 'Ę', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x0119': { c: 'ę', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x011A': { c: 'Ě', s: 6, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 14, f25r: 14, f35b: 20 },
    '0x011B': { c: 'ě', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 11, f25b: 14, f25r: 13, f35b: 21 },
    '0x011E': { c: 'Ğ', s: 7, b: 16, f18b: 11, f18r: 11, f20r: 13, f25b: 18, f25r: 17, f35b: 25 },
    '0x011F': { c: 'ğ', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0131': { c: 'ı', s: 2, b: 4, f18b: 4, f18r: 3, f20r: 4, f25b: 7, f25r: 6, f35b: 10 },
    '0x0132': { c: 'Ĳ', s: 7, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0133': { c: 'ĳ', s: 6, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x013E': { c: 'ľ', s: 5, b: 8, f18b: 7, f18r: 5, f20r: 6, f25b: 10, f25r: 7, f35b: 14 },
    '0x0141': { c: 'Ł', s: 7, b: 12, f18b: 9, f18r: 8, f20r: 9, f25b: 13, f25r: 13, f35b: 19 },
    '0x0142': { c: 'ł', s: 4, b: 6, f18b: 4, f18r: 3, f20r: 6, f25b: 7, f25r: 6, f35b: 10 },
    '0x0143': { c: 'Ń', s: 7, b: 12, f18b: 11, f18r: 11, f20r: 12, f25b: 18, f25r: 17, f35b: 26 },
    '0x0144': { c: 'ń', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0147': { c: 'Ň', s: 7, b: 12, f18b: 11, f18r: 11, f20r: 12, f25b: 18, f25r: 17, f35b: 26 },
    '0x0148': { c: 'ň', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0150': { c: 'Ő', s: 6, b: 12, f18b: 12, f18r: 12, f20r: 14, f25b: 19, f25r: 18, f35b: 27 },
    '0x0151': { c: 'ő', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 22 },
    '0x0152': { c: 'Œ', s: 11, b: 16, f18b: 17, f18r: 16, f20r: 18, f25b: 24, f25r: 24, f35b: 35 },
    '0x0153': { c: 'œ', s: 10, b: 19, f18b: 16, f18r: 16, f20r: 18, f25b: 24, f25r: 23, f35b: 34 },
    '0x0154': { c: 'Ŕ', s: 7, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 15, f25r: 13, f35b: 21 },
    '0x0155': { c: 'ŕ', s: 4, b: 8, f18b: 6, f18r: 5, f20r: 6, f25b: 10, f25r: 8, f35b: 14 },
    '0x0158': { c: 'Ř', s: 7, b: 12, f18b: 9, f18r: 8, f20r: 10, f25b: 15, f25r: 13, f35b: 21 },
    '0x0159': { c: 'ř', s: 4, b: 8, f18b: 6, f18r: 6, f20r: 6, f25b: 10, f25r: 9, f35b: 14 },
    '0x015A': { c: 'Ś', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x015B': { c: 'ś', s: 6, b: 11, f18b: 8, f18r: 7, f20r: 8, f25b: 12, f25r: 10, f35b: 17 },
    '0x015E': { c: 'Ş', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x015F': { c: 'ş', s: 6, b: 11, f18b: 8, f18r: 7, f20r: 8, f25b: 12, f25r: 10, f35b: 17 },
    '0x0160': { c: 'Š', s: 6, b: 12, f18b: 10, f18r: 9, f20r: 10, f25b: 14, f25r: 13, f35b: 20 },
    '0x0161': { c: 'š', s: 6, b: 11, f18b: 8, f18r: 7, f20r: 8, f25b: 12, f25r: 10, f35b: 17 },
    '0x0164': { c: 'Ť', s: 6, b: 12, f18b: 8, f18r: 8, f20r: 10, f25b: 13, f25r: 12, f35b: 19 },
    '0x0165': { c: 'ť', s: 6, b: 9, f18b: 8, f18r: 7, f20r: 9, f25b: 12, f25r: 11, f35b: 16 },
    '0x016E': { c: 'Ů', s: 6, b: 11, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x016F': { c: 'ů', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0170': { c: 'Ű', s: 6, b: 12, f18b: 11, f18r: 10, f20r: 12, f25b: 18, f25r: 17, f35b: 25 },
    '0x0171': { c: 'ű', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0178': { c: 'Ÿ', s: 6, b: 11, f18b: 10, f18r: 9, f20r: 10, f25b: 16, f25r: 15, f35b: 22 },
    '0x0179': { c: 'Ź', s: 6, b: 12, f18b: 20, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 20 },
    '0x017A': { c: 'ź', s: 6, b: 10, f18b: 8, f18r: 8, f20r: 9, f25b: 12, f25r: 12, f35b: 17 },
    '0x017B': { c: 'Ż', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 0 },
    '0x017C': { c: 'ż', s: 6, b: 10, f18b: 8, f18r: 8, f20r: 9, f25b: 12, f25r: 12, f35b: 17 },
    '0x017D': { c: 'Ž', s: 6, b: 12, f18b: 10, f18r: 10, f20r: 11, f25b: 15, f25r: 14, f35b: 20 },
    '0x017E': { c: 'ž', s: 6, b: 10, f18b: 8, f18r: 8, f20r: 9, f25b: 12, f25r: 12, f35b: 17 },
    '0x01C4': { c: 'Ǆ', s: 13, b: 24, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01C5': { c: 'ǅ', s: 13, b: 22, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01C6': { c: 'ǆ', s: 12, b: 20, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01C7': { c: 'Ǉ', s: 11, b: 20, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01C8': { c: 'ǈ', s: 9, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01C9': { c: 'ǉ', s: 6, b: 8, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01CA': { c: 'Ǌ', s: 12, b: 22, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01CB': { c: 'ǋ', s: 11, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01CC': { c: 'ǌ', s: 10, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FA': { c: 'Ǻ', s: 6, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FB': { c: 'ǻ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FC': { c: 'Ǽ', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FD': { c: 'ǽ', s: 10, b: 19, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FE': { c: 'Ǿ', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x01FF': { c: 'ǿ', s: 7, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x02C6': { c: 'ˆ', s: 4, b: 8, f18b: 9, f18r: 9, f20r: 10, f25b: 13, f25r: 12, f35b: 19 },
    '0x0391': { c: 'Α', s: 8, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0392': { c: 'Β', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0393': { c: 'Γ', s: 5, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0394': { c: 'Δ', s: 8, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0395': { c: 'Ε', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0396': { c: 'Ζ', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0397': { c: 'Η', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0398': { c: 'Θ', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0399': { c: 'Ι', s: 2, b: 4, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039A': { c: 'Κ', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039B': { c: 'Λ', s: 8, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039C': { c: 'Μ', s: 8, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039D': { c: 'Ν', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039E': { c: 'Ξ', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x039F': { c: 'Ο', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A0': { c: 'Π', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A1': { c: 'Ρ', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A3': { c: 'Σ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A4': { c: 'Τ', s: 6, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A5': { c: 'Υ', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A6': { c: 'Φ', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A7': { c: 'Χ', s: 7, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A8': { c: 'Ψ', s: 8, b: 17, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03A9': { c: 'Ω', s: 8, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B1': { c: 'α', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B2': { c: 'β', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B3': { c: 'γ', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B4': { c: 'δ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B5': { c: 'ε', s: 5, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B6': { c: 'ζ', s: 5, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B7': { c: 'η', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B8': { c: 'θ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03B9': { c: 'ι', s: 2, b: 4, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BA': { c: 'κ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BB': { c: 'λ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BC': { c: 'μ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BD': { c: 'ν', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BE': { c: 'ξ', s: 5, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03BF': { c: 'ο', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C0': { c: 'π', s: 9, b: 16, f18b: 11, f18r: 10, f20r: 12, f25b: 16, f25r: 16, f35b: 21 },
    '0x03C1': { c: 'ρ', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C2': { c: 'ς', s: 5, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C3': { c: 'σ', s: 8, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C4': { c: 'τ', s: 4, b: 8, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C5': { c: 'υ', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C6': { c: 'φ', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C7': { c: 'χ', s: 5, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C8': { c: 'ψ', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x03C9': { c: 'ω', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0401': { c: 'Ё', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0406': { c: 'І', s: 2, b: 4, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0410': { c: 'А', s: 8, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0411': { c: 'Б', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0412': { c: 'В', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0413': { c: 'Г', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0414': { c: 'Д', s: 7, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0415': { c: 'Е', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0416': { c: 'Ж', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0417': { c: 'З', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0418': { c: 'И', s: 7, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0419': { c: 'Й', s: 7, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041A': { c: 'К', s: 7, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041B': { c: 'Л', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041C': { c: 'М', s: 8, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041D': { c: 'Н', s: 7, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041E': { c: 'О', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x041F': { c: 'П', s: 7, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0420': { c: 'Р', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0421': { c: 'С', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0422': { c: 'Т', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0423': { c: 'У', s: 8, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0424': { c: 'Ф', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0425': { c: 'Х', s: 7, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0426': { c: 'Ц', s: 7, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0427': { c: 'Ч', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0428': { c: 'Ш', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0429': { c: 'Щ', s: 9, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042A': { c: 'Ъ', s: 7, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042B': { c: 'Ы', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042C': { c: 'Ь', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042D': { c: 'Э', s: 7, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042E': { c: 'Ю', s: 9, b: 17, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x042F': { c: 'Я', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0430': { c: 'а', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0431': { c: 'б', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0432': { c: 'в', s: 5, b: 9, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0433': { c: 'г', s: 4, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0434': { c: 'д', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0435': { c: 'е', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0436': { c: 'ж', s: 8, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0437': { c: 'з', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0438': { c: 'и', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0439': { c: 'й', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043A': { c: 'к', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043B': { c: 'л', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043C': { c: 'м', s: 6, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043D': { c: 'н', s: 6, b: 9, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043E': { c: 'о', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x043F': { c: 'п', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0440': { c: 'р', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0441': { c: 'с', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0442': { c: 'т', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0443': { c: 'у', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0444': { c: 'ф', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0445': { c: 'х', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0446': { c: 'ц', s: 7, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0447': { c: 'ч', s: 5, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0448': { c: 'ш', s: 8, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0449': { c: 'щ', s: 9, b: 16, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044A': { c: 'ъ', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044B': { c: 'ы', s: 7, b: 15, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044C': { c: 'ь', s: 5, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044D': { c: 'э', s: 6, b: 11, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044E': { c: 'ю', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x044F': { c: 'я', s: 5, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0451': { c: 'ё', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0456': { c: 'і', s: 2, b: 4, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0462': { c: 'Ѣ', s: 10, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0463': { c: 'ѣ', s: 10, b: 13, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0472': { c: 'Ѳ', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0473': { c: 'ѳ', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0474': { c: 'Ѵ', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x0475': { c: 'ѵ', s: 8, b: 14, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x04AE': { c: 'Ү', s: 6, b: 12, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x04AF': { c: 'ү', s: 6, b: 10, f18b: 0, f18r: 0, f20r: 0, f25b: 0, f25r: 0, f35b: 0 }, // not found
    '0x2018': { c: '‘', s: 3, b: 5, f18b: 4, f18r: 3, f20r: 3, f25b: 5, f25r: 4, f35b: 8 },
    '0x2019': { c: '’', s: 3, b: 5, f18b: 4, f18r: 3, f20r: 4, f25b: 5, f25r: 4, f35b: 8 },
    '0x201A': { c: '‚', s: 3, b: 5, f18b: 4, f18r: 3, f20r: 3, f25b: 5, f25r: 4, f35b: 8 },
    '0x201C': { c: '“', s: 6, b: 9, f18b: 7, f18r: 5, f20r: 6, f25b: 11, f25r: 8, f35b: 15 },
    '0x201D': { c: '”', s: 6, b: 9, f18b: 7, f18r: 5, f20r: 6, f25b: 11, f25r: 8, f35b: 15 },
    '0x201E': { c: '„', s: 6, b: 9, f18b: 7, f18r: 5, f20r: 6, f25b: 11, f25r: 8, f35b: 15 },
    '0x2039': { c: '‹', s: 5, b: 7, f18b: 6, f18r: 6, f20r: 6, f25b: 9, f25r: 7, f35b: 11 },
    '0x203A': { c: '›', s: 5, b: 7, f18b: 6, f18r: 6, f20r: 6, f25b: 9, f25r: 7, f35b: 12 }
  },

  validate_space_pump_field: function() {
    var target = $(this);
    var stringToValidate = $(this).val();
    var pumpAttribute = BBraunSpaceTwoPump.attributeLimits[$(this).attr("pump_attribute")];

    if(pumpAttribute == undefined) {
      console.log("The pump attribute: '" + $(this).attr("pump_attribute") + "' is not valid.");
      return false;
    }

    var f18bTextTotal = 0, f18rTextTotal = 0, f20rTextTotal = 0, f25bTextTotal = 0, f25rTextTotal = 0, f35bTextTotal = 0, bytesTotal = 0;

    try {
      for (var i=0; i < stringToValidate.length; i++) {
        var chrCode = stringToValidate.charCodeAt(i);
        var utf8 = ("0x" + ("0000"+(chrCode.toString(16))).slice(-4).toUpperCase());
        var sizes = BBraunSpaceTwoPump.characterSizes[utf8];
        if (sizes == undefined) throw pumpAttribute.display + " contains unsupported characters: " + stringToValidate.charAt(i);

        f18bTextTotal += sizes.f18b;
        f18rTextTotal += sizes.f18r;
        f20rTextTotal += sizes.f20r;
        f25bTextTotal += sizes.f25b;
        f25rTextTotal += sizes.f25r;
        f35bTextTotal += sizes.f35b;

        bytesTotal += (chrCode < 0x80 ? 1 : (chrCode < 0x800 ? 2 : 3));
      }
      if(
        f18bTextTotal > pumpAttribute.f18b ||
        f18rTextTotal > pumpAttribute.f18r ||
        f20rTextTotal > pumpAttribute.f20r ||
        f25bTextTotal > pumpAttribute.f25b ||
        f25rTextTotal > pumpAttribute.f25r ||
        f35bTextTotal > pumpAttribute.f35b ||
        bytesTotal    > pumpAttribute.maxBytes
      ) {
        throw pumpAttribute.display + " is too long.";
      }
      target.css("background-color", "").attr("title", "");
    }
    catch(errorMessage) {
      target.css("background-color", "#f0a8a8").attr("title", errorMessage);
      target.mouseover();
    }
  }
}

$(document).ready(function() {
  $("body").on('keyup', '.space_two_pump_display_validation', BBraunSpaceTwoPump.validate_space_pump_field)
});
