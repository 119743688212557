$(document).ready(function() {
  var lastHeartBeatToServer = new Date();
  if (Page.timeoutEnabled) {
    $(document).bind('keypress mousemove scroll', function() {
      if((new Date() - lastHeartBeatToServer) >= ApplicationSettings.heartBeatInterval) {
        $.post(Route.sessionPath(), {_method: "put", authenticity_token: Page.authenticityToken});
        lastHeartBeatToServer = new Date();
      }
    });
  }
});
