var ActionMenuSorter = {
  sortAlpha: function (a,b){  
      return $(a).children("a:first").text().toLowerCase() > $(b).children("a:first").text().toLowerCase() ? 1 : -1;  
   }
};

$(document).ready(function() {
  
  $('div#search_actions ul.sorted_list li').sort(ActionMenuSorter.sortAlpha).appendTo('div#search_actions ul.sorted_list');
  $('div#dr_actions ul.sorted_list li').sort(ActionMenuSorter.sortAlpha).appendTo('div#dr_actions ul.sorted_list');
  $('div#hospital_actions ul.sorted_list li').sort(ActionMenuSorter.sortAlpha).appendTo('div#hospital_actions ul.sorted_list');

});
