import I18n from 'i18n-js/index.js.erb';
import DrugRecordFilter from './drug_record_filter.js';
import Group from './group.js';
import PleaseWaitDialog from './page_loading.js';

var Search = {
  extractDrugRecordIds: function() {
    if(Search.thereAreCheckBoxes()) {
      var drugRecordIds = $.map(Search.allCheckedBoxes(), function(elt) { return $(elt).val(); });
      $(this).find("input[name='drug_record_ids']").val(drugRecordIds.join(','));
    }
  },

  cloneDrugRecordIds: function() {
    $(this).find(".drug_record_ids").empty().append(Search.allCheckedBoxes().clone());
  },

  resetAndClose: function() {
    $("form[1]").each(function() { this.reset(); });
    $('.modal').modal('hide');
  },

  showCloneForm: function() {
    $("#add_selected_drug_record").modal('show');
  },

  showBulkRecordAlertForm: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_alert'));
    } else {
      $("#create_bulk_drug_record_alert").modal('show');
    }
  },

  displayCompareMessage: function(messageId) {
    $("#flash_container").flash("alert-danger", Search.Errors[message_id]);
  },

  showChangeStatusPage: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_approval_status'));
    } else {
      PleaseWaitDialog.show();
      var form = $("form#status_form");
      form.empty().append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      form.submit();
    }
  },

  showComparePage: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 2) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.more_than_one_record'));
    } else if(checkedBoxes.length > 20) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.fewer_than_twenty_records'));
    } else {
      PleaseWaitDialog.show();
      var form = $("form#compare_form");
      form.empty().append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      form.submit();
    }
  },

  doExport: function( event ) {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_export'));
    } else {
      var form = $("form#export_form");
      form.empty();
      form.append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      var export_token = Math.ceil(Math.random() * 10000000);
      form.append("<input type='hidden' name='export_token' value='" + export_token + "'></input>");
      PleaseWaitDialog.show();
      form.submit();
      (function checkForToken() {
        setTimeout(function() {
          if ($.cookie('primer_search_export_token') != export_token) {
            checkForToken();
          } else {
            $(".modal").modal('hide');
          }
        }, 1000);
      })();
    }
  },

  doPrintDataGrid: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_export'));
    } else {
      var form = $("form#data_grid_form");
      form.empty();
      form.append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      form.submit();

    }
  },

  doDelete: function() {
     var checkedBoxes = Search.allCheckedBoxes();
     if(checkedBoxes.length < 1) {
       $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_delete'));
     } else {
        if(confirm(I18n.t('drug_records.destroy.confirm'))) {
            var form = $("form#deactivate_form");
            form.empty();
            form.append(Search.allCheckedBoxes().clone());
            form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
            PleaseWaitDialog.show(I18n.t('shared.primer_dialog.dialog_page_loading.caption.delete_drug_records'));
            form.submit();
        }
     }
   },

  thereAreCheckBoxes: function() {
    return ($("#search_results .result input.search_result_check_box").filter(':visible').length !== 0);
  },

  allCheckedBoxes: function() {
    return $("#search_results .result input.search_result_check_box:checked").filter(':visible');
  },

  doEditQueue: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_edit'));
    } else {
      PleaseWaitDialog.show();
      var form = $("form#edit_queue_form");
      form.empty();
      form.append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      form.submit();
    }
  },
  doCopyQueue: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_edit'));
    } else {
      PleaseWaitDialog.show();
      var form = $("form#copy_queue_form");
      form.empty();
      form.append(Search.allCheckedBoxes().clone());
      form.append("<input type='hidden' name='authenticity_token' value='" + Page.authenticityToken + "'></input>");
      form.submit();
    }
  },
  doCopySingleRecord: function() {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length != 1) {
      $("#flash_container").flash("alert-danger", I18n.t('browse.search.errors.select_copy'));
    } else {
      PleaseWaitDialog.show();
      var originalRecordId = $(checkedBoxes[0]).val();
      window.location.href = "/drug_records/new?original_record_id="+ originalRecordId;
    }
  }
};

export default Search;

function reloadPage() {
  $.cookie('primer_search_per_page', $(this).val(), { expires:360, path:'/' });
  if ($.cookie('primer_search_per_page') == "10000") {
    PleaseWaitDialog.show();
  }
  location.reload();
}

function restripeResults() {
  $.each($('tr.result:visible'), function(index, value) {
    $(value).removeClass('even');
    $(value).removeClass('odd');
    if(index % 2 === 0) {
      $(value).addClass('even');
    } else {
      $(value).addClass('odd');
    }
  });
}

// enableSorting now takes the contents of the restrict_to dropdown as an argument.
//  this allows us to suppress the js-based table sorting for 'everything' queries, 
//  which should instead sort by score.
function enableSorting(restrict_to) {
  var table_head;
  var sort;

  if (typeof(table_head) === 'undefined') {
    table_head = $('table#search_results thead').clone();
  } else {
    $('table#search_results thead').replaceWith(table_head.clone());
  }

  function columnIndex(columnTitle) {
    return $("tr.headers").children().index($("th:contains('" + columnTitle + "')"));
  }

  if ($.cookie('primer_search_sort')) {
    var sortDirection = $.cookie('primer_search_sort').match(/\|DESC$/) ? 1 : 0;
    if ($.cookie('primer_search_sort').match(/^[^|]*/)) {
      var match = $.cookie('primer_search_sort').match(/^[^|]*/)[0];
      if (columnIndex(match) != -1) {
        sort = [[columnIndex(match), sortDirection]];
      }
    } else {
      if (columnIndex('Record Label') != -1 && columnIndex('Generic Name') != -1) {
        sort = [[columnIndex('Record Label'), sortDirection], [columnIndex('Generic Name'), sortDirection]];
      }
    }
  }

  if (typeof(sort) == 'undefined') {
    if (columnIndex('Generic Name') != -1) {
      sort = [[columnIndex('Generic Name'), 0]];
    } else if (columnIndex('Drug Name') != -1) {
      sort = [[columnIndex('Drug Name'), 0]];
    } else {
      sort = [[columnIndex('Drug Record'), 0]];
    }
  }

  if (restrict_to == 'drug_name|brand_name') {
    $("#search_results").tablesorter({
      sortList: sort
    });
  } else {
    if(application_current_pump_type == "Pump::BBraunSpaceTwoPump"){
      $("#search_results").tablesorter({
        sortList: [[3,0]]
      });
    } else {
      $("#search_results").tablesorter({
      }); 
    }
  }

  $("#search_results").bind("sortEnd", function() {
    $(this).find(".tablesorter-headerAsc div.tablesorter-header-inner").each(function() {
      $.cookie('primer_search_sort', ($.trim($(this).text()) + "|ASC"), { expires:360 });
    });
    $(this).find(".tablesorter-headerDesc div.tablesorter-header-inner").each(function() {
      $.cookie('primer_search_sort', ($.trim($(this).text()) + "|DESC"), { expires:360 });
    });
    restripeResults();
  });
}

function enableSelectAll() {
  $("#select_all_search_results").selectAll(".search_result_check_box");
}

function DrugRecordListPaginator() {
  var table_body = $('table#search_results tbody');
  var filter = $('#filter');
  var previous_button = $('#pagination_links #previous');
  var next_button = $('#pagination_links #next');
  var all_page_links = $('#pagination_links .page_link');
  var all_page_buttons = [];
  var current_page_button;
  var loading_page = false;

  function bindNextButton(page_link) {
    page_link = page_link || $('.page_link[page="2"]');
    next_button.click(function() {
      page_link.click();
    });
  }

  function unbindNextButton() {
    next_button.unbind('click');
  }

  function bindPreviousButton(page_link) {
    previous_button.click(function() {
      page_link.click();
    });
  }

  function unbindPreviousButton() {
    previous_button.unbind('click');
  }

  function PageButton(element) {
    var self = this;
    var page_link = element;
    var number = page_link.find('.number');
    var spinner = page_link.find('.spinner');
    var page_number = parseInt(page_link.attr('page'), 10);
    var previous_page_number = page_number - 1;
    var previous_page_link = $('.page_link[page="' + previous_page_number +'"]');
    var next_page_number = page_number + 1;
    var next_page_link = $('.page_link[page="' + next_page_number +'"]');
    var is_last_page = !next_page_link.length;

    this.bindPageButton = function() {
      page_link.click(function(e) {
        if (!loading_page) {
          loading_page = true;
          unbindPageButton();
          current_page_button.bindPageButton();
          current_page_button = self;
          number.hide();
          spinner.show();
          page_link.siblings().removeClass('current');
          page_link.addClass('current');
          if (previous_page_number > 0) {
            previous_button.removeClass('disabled');
            unbindPreviousButton();
            bindPreviousButton(previous_page_link);
          } else {
            previous_button.addClass('disabled');
            unbindPreviousButton();
          }
          if (!is_last_page) {
            next_button.removeClass('disabled');
            unbindNextButton();
            bindNextButton(next_page_link);
          } else {
            next_button.addClass('disabled');
            unbindNextButton();
          }
          $.getJSON(page_link.attr("path"), function(data) {
            table_body.empty();
            table_body.append(data.record_list);
            filter.replaceWith(data.filter);
            DrugRecordFilter.configureDropdown();
            DrugRecordFilter.resetFilterForm();
            spinner.hide();
            number.show();
            $("#search_results").trigger("update");
            $("#select_all_search_results").selectAll(".search_result_check_box");
            restripeResults();
            loading_page = false;
            $("#select_all_search_results").unbind( "change" ); // unbinds same logic from browse.js which is ran on page refresh
            $('.admin_info').hide(); // hide this message when going to new page, logic to show it below

            // make sure that select_all is not checked when going to new page
            if(page_link.attr("page") != "All" && $('#select_all_search_results').is(':checked')){
              $("#select_all_search_results").click();
            }

            // show / hide message
            $("#select_all_search_results").change(function(){
              if(this.checked && page_link.attr("page") != "All"){
                $('.admin_info').show();
              } else {
                $('.admin_info').hide();
              }
            })

            window.history.pushState({}, '', page_link.attr("path").replace(/browse_rows/, 'browse'));
          });
        }
      });
    };

    function unbindPageButton() {
      page_link.unbind('click');
    }
  }

  bindNextButton();

  all_page_links.each(function() {
    var page_button = new PageButton($(this));
    all_page_buttons.push(page_button);
    if (all_page_buttons.length > 0) {
      page_button.bindPageButton();
    }
    current_page_button = page_button;
  });
}

$(document).ready(function() {
  // when user clicks this button, they are going to all records on a hard link so we just show the loader without closing it
  $("#browse-all-select-all").click(function () {
    PleaseWaitDialog.show();
  });

  $("#restrict_to_container").change(function (e) {
    searchAutocomplete.cachedResponse = [];
    searchAutocomplete.options.ajaxSettings.params.restrict_to = $(
      e.target
    ).val();
  });

  $("#search_in_field").change(function (e) {
    searchAutocomplete.cachedResponse = [];
    searchAutocomplete.options.ajaxSettings.params.search_value = $(
      e.target
    ).val();
  });

  $("body").on("click", "input.search_result_check_box", function (event) {
    if (Search.allCheckedBoxes().length == 1) {
      $("a#copy_single_record_link").show();
    } else {
      $("a#copy_single_record_link").hide();
    }
  });

  $(window).on("popstate", function () {
    $.get(
      document.location.href.replace(/(browse|search)/, "$1_rows"),
      function (data) {
        var table_body = $("table#search_results tbody");
        var filter = $("#filter");
        $(".page_link").removeClass("current");
        $('.page_link[page="' + data.page + '"]').addClass("current");
        table_body.empty();
        table_body.append(data.record_list);
        filter.replaceWith(data.filter);
        filter = $("#filter");
        DrugRecordFilter.configureDropdown();
        DrugRecordFilter.resetFilterForm();
        $("#search_results").trigger("update");
        $("#select_all_search_results").selectAll(".search_result_check_box");
        restripeResults();
      }
    );
  });

  $("#select_all_search_results").toggleClass("hidden");
  var searchAutocomplete = $("#query").devbridgeAutocomplete({
    serviceUrl: Route.autocompleteSearchPath,
    autoSubmit: true,
    noCache: true,
    triggerSelectOnValidInput: false,
    onSelect: function () {
      $("#query").parents("form").submit();
    },
    ajaxSettings: {
      params: {
        restrict_to: $("#restrict_to_container").val(),
        search_value:
          $("#search_in_field").val() ||
          $("#hidden_search_value").attr("value"),
      },
    },
  });

  $("#add_to_groups_button").click(function (e) {
    e.preventDefault();
    Group.showAddToGroupForm();
  });
  $("#add_to_group_form").submit(Search.extractDrugRecordIds);
  $("#add_to_group_cancel").click(Search.resetAndClose);

  $("#remove_from_groups_button").click(Group.removeFromFavorites);

  $("#create_bulk_drug_record_alerts_button").click(function (e) {
    e.preventDefault();
    Search.showBulkRecordAlertForm();
  });
  $("#drug_record_bulk_alert_form").submit(Search.extractDrugRecordIds);

  $("#compare_search_results_button").click(function (e) {
    e.preventDefault();
    Search.showComparePage();
  });

  $("#export_button").click(function (e) {
    e.preventDefault();
    Search.doExport();
  });

  $("#print_grid_button").click(function (e) {
    e.preventDefault();
    Search.doPrintDataGrid();
  });

  $("#edit_queue_link").click(function (e) {
    e.preventDefault();
    Search.doEditQueue();
  });

  $("#copy_queue_link").click(function (e) {
    e.preventDefault();
    Search.doCopyQueue();
  });

  $("#copy_single_record_link").click(function (e) {
    e.preventDefault();
    Search.doCopySingleRecord();
  });

  $("#change_approval_status_action_link, #change_approval_status_link").click(
    function (e) {
      e.preventDefault();
      Search.showChangeStatusPage();
    }
  );

  $("#delete_drug_records_link").click(function (e) {
    e.preventDefault();
    Search.doDelete();
  });

  $("select#_per_page").change(reloadPage);

  DrugRecordListPaginator();

  if ($("tr.headers").length > 0) {
    enableSorting($("#restrict_to_container").val());
  }

  restripeResults();

  // 2017-03-06: This call MUST come after sorting is enabled, otherwise, the sorting code siezes the click action.
  enableSelectAll();
});
