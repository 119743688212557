var DrugRecordFilter = {
  filterByCheckedValue: function(e) {
    var target = $(e.target);
    if(target.attr("class").includes("custom_checkbox_label")) {
      target = $(e.target).siblings(".custom_checkbox")
    }
    var visible = typeof(visible) == 'undefined' ? $('.drug_record') : visible;
    var excluded = typeof(excluded) == 'undefined' ? {} : excluded;
    var group = $(target).attr('group');
    excluded[group] = excluded[group] || [];
    var label = $(target).attr('label');
    var checked = $(target).hasClass('checked');
    if (checked) {
      excluded[group].splice($.inArray(label, excluded[group]), 1);
      visible = $('.drug_record').filter(function(i, elem) {
        return DrugRecordFilter.determineVisibility(elem, excluded, group);
      });
      $('.drug_record').show();      
    } else {
      // This handles hiding elements which get unchecked.
      excluded[group].push(label);
      switch(group) {
      case 'care_area_names':
        // When a care area is unchecked, we only hide a row if *all* the record's care areas are unchecked.
        visible = visible.filter(function(i, elem) {
          var matches = 0;
          $(excluded[group]).each(function(index) {
            $(elem).find('.care_area_names').find('.filter_label').each(function(idx, el) {
              if($(el).text() == excluded[group][index])
                matches = matches + 1;
            });
          });
          if(matches !== $(elem).children(".care_area_names").children(".filter_label").length) 
            return true;
        });
        break;
      case 'supported_pumps_string':
        // When a pump type is unchecked, we only hide a row if *all* the record's pump types are unchecked.
        visible = visible.filter(function(i, elem) {
          var matches = 0;
          $(excluded[group]).each(function(index) {
            $(elem).find('.supported_pumps_string').find('.filter_label').each(function(idx, el) {
              if($(el).text() == excluded[group][index])
                matches = matches + 1;
            });
          });
          if(matches !== $(elem).children(".supported_pumps_string").children(".filter_label").length) 
            return true;
        });
        break;
      default:
        // For anything other than a care area or pump type, if something is unchecked, hide it.
        visible = visible.filter(function() {
          return $(this).find('.' + group).find('.filter_label:contains("'+label+'")').length === 0;
        });        
      }
    }
    $('.drug_record').not(visible).hide();
    DrugRecordFilter.restripeResults();
  },

  restripeResults: function() {
    $.each($('tr.result:visible'), function(index, value) {
      $(value).removeClass('even');
      $(value).removeClass('odd');
      if(index % 2 === 0) {
        $(value).addClass('even');
      } else {
        $(value).addClass('odd');
      }
    });  
  },

  determineVisibility: function(elem, excluded, group) {
    var matches = 0;
    var hidden = false;
    $.each(excluded, function(group_name, excluded_group) {
      $.each(excluded_group, function(g_idx, lbl) {
        if ($(elem).find('.' + group_name).find('.filter_label:contains("'+lbl+'")').length !== 0) {
          // If any name matches an unchecked value, stay hidden, unless it's a care area or pump type, in which case we build up to keeping it hidden.
          if (group_name == 'care_area_names') {
            matches = matches + 1;
          } else if (group_name == 'supported_pumps_string') {
            matches = matches + 1;
          } else {
            hidden = true;
          }
        }
      });
    });
    if (matches > 0 && matches == $(elem).children('.' + group).children('.filter_label').length
      || hidden == true) {
      // If all of the care area names were hits, keep hidden.
      return false;
    }
    return true;
  },

  showHideFilterSection: function(e) {
    if ($(this).siblings(".filter_options").is(":visible")) {
      $(this).removeClass("contractable_header").addClass("expandable_header");
    } else {
      $(this).removeClass("expandable_header").addClass("contractable_header");
    }
    $(this).siblings(".filter_options").toggle();
    e.stopPropagation();
  },

  resetFilterForm: function() {
    $(".filter_group .filter_options").hide();
    $(".filter_group .filter_heading").removeClass("contractable_header").addClass("expandable_header");
    $("#filter .custom_checkbox").each(function() {
      $(this).addClass("checked");
    });
    $(".drug_record").show();
    var visible = undefined;
    var excluded = undefined;
    return false;
  },

  configureDropdown: function() {
    $(".custom_checkbox").customCheckbox();
    $("#filter").each(function() {
      var root = $(this);
      root.find(".filter_group .filter_heading").click(DrugRecordFilter.showHideFilterSection);
      root.find(".filter_value .custom_checkbox").click(DrugRecordFilter.filterByCheckedValue);
      root.find(".filter_value .custom_checkbox_label").click(DrugRecordFilter.filterByCheckedValue);
      root.find("#reset_filter_options_button").click(DrugRecordFilter.resetFilterForm);
    });
  },

  initializeDropdown: function() {
    DrugRecordFilter.configureDropdown();
  }
};

export default DrugRecordFilter;

$.fn.customCheckbox = function() {
  return $(this).each(function() {
    var self  = $(this);
    var field = self.find("input");
    $(this).add($(this).siblings(".custom_checkbox_label")).click(function(e) {
      self.toggleClass("checked");
      field.attr("disabled", !field.is(":disabled")).change();
      e.stopPropagation();
    });
  });
};

$(document).ready(function() {
  DrugRecordFilter.initializeDropdown();
})