import PleaseWaitDialog from './page_loading.js';
import Search from './search.js';

var Group = {
  showAddToGroupForm: function(e) {
    var checkedBoxes = Search.allCheckedBoxes();
    if(Search.thereAreCheckBoxes() && checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", "Please select at least 1 record to add to favorites.");
    } else {
      $("#select_group").modal('show');
    }
  },
  
  closeGroupForm: function(e) {
    $("#select_group").modal('hide');
    e.preventDefault();
  },

  removeFromFavorites: function(e) {
    var checkedBoxes = Search.allCheckedBoxes();
    if(checkedBoxes.length < 1) {
      $("#flash_container").flash("alert-danger", "Please select at least 1 record to remove.");
    } else {
      PleaseWaitDialog.show();
      var form = $("form#remove_from_group_form");
      form.append(Search.allCheckedBoxes().clone());
      form.submit();
    }
  }
}

export default Group;