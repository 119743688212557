$(document).ready(function () {
  
$('input[type=submit]', this).removeAttr('disabled');

// this will disable all submit elements after a form is submitted to prevent duplicate submissions
//to prevent an element from being disabled, set the keep_enabled attribute to true   
$('form').submit(function(){
      $('input[type=submit]', this).not("[keep_enabled=true]").attr('disabled', 'disabled');
  });

});