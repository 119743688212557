var Tooltip = {
  enableTooltips: function() {
    $('img.ik_tooltip').tooltip({ show: { delay: 500 }});
    $('img.ik_label_tooltip').tooltip({ show: { delay: 500 }});
    $('abbr.concentration_pump_type_abbr').tooltip({ show: {delay: 250}, position: { my: "left+15 center", at: "right center" }});
    $('div.ik_tooltip').tooltip({ show: { delay: 500 }});
    $('input.ik_tooltip').tooltip({ show: { delay: 500 }});
  }
}
export default Tooltip;

$(document).ready(function() {
  Tooltip.enableTooltips();
});
