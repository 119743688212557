import jQuery from 'jquery';

(function($) {
  function DynamicRowCloner(root, rowSelector, opts) {
    this.root = $(root);
    var self = this;

    this.rowSelector = rowSelector;
    
    this.options = {
      addRowButton:     ".add_row",
      removeRowButton:  ".delete_row",
      hideRow:          false,
      resetHidden:      false
    };
    
    if (opts) { $.extend(this.options, opts); }
    
    this.root.find(this.options.addRowButton).click(function(e) { self.addRow(this, e); });
    this.root.find(this.options.removeRowButton).click(function(e) { self.removeRow(this, e); });
  }
  
  $.extend(DynamicRowCloner.prototype, {
    resetFormElements: function(newRow) {
      if(this.options.resetHidden) {
        newRow.find("input").each(function() {
          $(this).val("");
          $(this).removeClass('text_field_error')
        });
      } else {
        newRow.find("input").not("input[type='hidden']").each(function() {
          $(this).val("");
          $(this).removeClass('text_field_error')
        });
      }
      newRow.find("input[type='file']").val("");
      newRow.find("span.dynamic_data").text(""); // sometimes we display static info that still needs to be cleared.
      newRow.find("input[type='checkbox']").removeAttr('checked');
      newRow.find("img.delete_row").show(); // show the delete link for the newly added row.
      newRow.find("select").attr('disabled', false).each(function(i, selectBox) { selectBox.selectedIndex = 0; });
    },
    
    addRow: function(button, e) { 
      var containingRow = $(button).parents(this.rowSelector);
      containingRow.find("img.delete_row").show(); // restore the delete link for the calling row (if it was hidden).
      var newRow = containingRow.clone(true);
      newRow.insertAfter(containingRow);
      this.resetFormElements(newRow);

      this.root.trigger("rowAdded", newRow);
    },
    
    removeRow: function(button, e) {
      var rowToRemove = $(button).parents(this.rowSelector);
      rowToRemove[this.options.hideRow ? "hide" : "remove"]();
      this.root.trigger("rowRemoved", rowToRemove);
      // This logic will hide the remove link when only one (visible) element remains.
      if(this.root.find(this.rowSelector+":visible").length == 1) {
        this.root.find(this.rowSelector + " img.delete_row").hide();
      }
    }
  });
  
  $.fn.dynamicRowCloner = function(rowSelector, opts) {
    return $(this).each(function() { return new DynamicRowCloner(this, rowSelector, opts); });
  };
})(jQuery);
