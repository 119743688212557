var PleaseWaitDialog = {
  show: function(n) {
    if (n && n.length > 0) {$("#page_loading_text").text(n);}
    $("#page_loading_dialog").modal('show');
    $("#page_loading_content img.spinner").show();
  },
  hide: function() {
    $("#page_loading_content img.spinner").hide();
    $("#page_loading_dialog").modal('hide');
  }
};

export default PleaseWaitDialog;

$(document).ready(function() {
  $(".page_loading_link").on('click', function(e) { PleaseWaitDialog.show() });
});
