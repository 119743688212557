import jQuery from 'jquery';

var Util = {
  BACKSPACE_KEY: 8,
  DELETE_KEY: 46,
  ARROW_KEY_START: 37,
  ARROW_KEY_END: 40,
  ABSOLUTELY_MEANINGLESS_CONSTANT: 2,

  isArrowKey: function(keyCode) {
    return (keyCode >= Util.ARROW_KEY_START || keyCode <= Util.ARROW_KEY_END);
  },

  isValidTextInput: function(keyCode) {
    return (!Util.isArrowKey(keyCode) && keyCode != Util.BACKSPACE_KEY && keyCode != Util.DELETE_KEY);
  }
};

// var Flash = {
//   init: function() {
//     $("#flash_container .messages_close a").click(function(e) { e.preventDefault(); $(this).parents(".flash_type_container").fadeOut("slow"); });
//   }
// };

$.fn.flash = function(messageType, message) {
  var messageHtml = tmpl($("#flash_message_template").html(), { key: messageType, message: message });
  var flashContainer = $(this);
  flashContainer.css("z-index", flashContainer.nextHighestZIndex());
  flashContainer.empty().append(messageHtml).show();
  flashContainer.find(".messages_close a").click(function(e) { e.preventDefault(); $(this).parents(".flash_type_container").fadeOut("slow"); });
};

var JQueryExtensions = {
  nextHighestZIndex: function() {
    return Math.max.apply(Math, $.map($(this).parents(), function(e, n) {
      var pos = $(e).css('position');
      if (pos === 'absolute' || pos === 'relative' || pos === 'fixed') {
        return parseInt($(e).css('z-index'), 10) || 1;
      }
    })) + 1;
  },

  fadeOutIfSupported: function(speed) {
    $(this).each(function() {
      if ($.support.opacity) {
        $(this).fadeOut(speed);
      } else {
        $(this).hide();
      }
    });
  },

  limitNumberOfChars: function(maxLength) {
    $(this).each(function() {
      var field = $(this);
      field.keypress(function(event) {
        if (Util.isValidTextInput(event.keyCode) && field.val().length >= maxLength) {
          return false;
        }
      });
    });
  },

  hideEmptyTables: function() {
    $(this).each(function() {
      var drugRow = $(this);
      var drugSectionRow = $.trim(drugRow.text());

      if (drugSectionRow === "" || drugSectionRow == drugRow.find(".ignore_for_collapse").text()) {
        drugRow.parents('.drug_section').hide();
      }
    });
  },

  selectAll: function(checkboxLocator) {
    var self = this;
    self.attr('checked', false);
    $(this).click(function() {
      $(checkboxLocator).prop('checked', self.is(':checked'));
    });
    $(checkboxLocator).click(function () {
      self.prop('checked', $(checkboxLocator + ":checked").length == $(checkboxLocator).length);
    });
  },

  swapClass: function(firstClass, secondClass) {
    return $(this).each(function() {
      var self = $(this);
      if (self.hasClass(firstClass)) {
        self.removeClass(firstClass).addClass(secondClass);
      } else {
        self.removeClass(secondClass).addClass(firstClass);
      }
    });
  },
};

$.fn.extend(JQueryExtensions);

$.aliasMethodChain = function(fnName, featureName, newFn) {
  $.fn[fnName + "Without" + featureName] = $.fn[fnName];
  $.fn[fnName] = $.fn[fnName + "With" + featureName] = newFn;
};

$(document).ajaxError(function (event, request, options, thrownError) {
  if(request.status === 403) {
    document.location = Route.loginPath;
  }
});

$.fn.showHideSectionOnClick = function(container, expandable_section) {
  $(this).each(function() {
    $(this).click(function() {
      $(this).toggleClass("expanded");
      $(this).parents(container).find(expandable_section).each(function() {
        var expandable = $(this);
        expandable.toggleClass("hidden");
      });
    });
  });
};

$.tablesorter.addParser({
  id: 'locked',
  is: function(s, table, cell, $cell) {
    return false;
  },
  format: function(s, table, cell, cellIndex) {
    return $(cell).find('img.search_result_lock_icon').length;
  },
  parsed: false,
  type: 'numeric'
});


$(document).ready(function() {
  // https://developer.mozilla.org/en/How_to_Turn_Off_Form_Autocompletion
  $("form").attr("autocomplete", "off");

  $("a.submit_link").click( function() {
    $(this).parents("form").submit();
  });

  $("input[type='button'][href]").click(function() {
    document.location = $(this).attr('href');
  });

  // $(".custom_select_box").not(".evented_select_box").customSelectBox();

  $(document).on('click', '.delete_votes_button', function(e) {
    if(confirm($(e.target.closest(".delete_votes_button")).attr('data-confirm'))) {
      $(e.target).parents('form').submit();
    }
    e.preventDefault();
  });

  // toast message configuration - limited to specific controllers due to it not running in partial for some reason
  if(rails_controller == "change_approval_statuses"){
    $('#bootstrap-toast-message').toast({
      delay: 3000
    });
    $('.toast').toast('show');
    $('#bootstrap-toast-message').on('hidden.bs.toast', function () {
      $('#bootstrap-toast-message').remove();
    });
  }
  // $('.toast').toast('show');
  // Flash.init();
});
